/* Основной контейнер для десктопов и планшетов */
.homepage-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.header {
    text-align: left;
    margin-bottom: 20px;
}

.header h1 {
    font-size: 36px;
    color: #333;
}

.header h2 {
    font-size: 18px;
    color: #666;
}

/* Навигация для десктопов */
.navigation {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
}

.navigation button {
    background-color: #4B4F9B;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
}

.navigation button:hover {
    background-color: #333;
}

.search-input {
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Карточки оборудования */
.equipment-section {
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
}

.equipment-card {
    background-color: #f4f4f4;
    border: 1px solid #ddd;
    width: 30%;
    padding: 20px;
    text-align: center;
    border-radius: 5px;
}

.equipment-card h3 {
    margin: 10px 0;
}

.equipment-card p {
    color: #777;
}

.image-placeholder {
    width: 100%;
    height: 150px;
    background-color: #ccc;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Медиазапросы для экранов до 768px (планшеты) */
@media (max-width: 768px) {
    .navigation {
        flex-direction: column;
        align-items: center;
        /* width: 100%; */
    }

    .navigation .search-input {
        width: 100%;
        margin-top: 10px;
    }

    .equipment-section {
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }

    .equipment-card {
        width: 90%;
        margin-bottom: 20px;
    }

    .header {
        text-align: center;
    }

    .navigation button,
    .button-link {
        width: 100%;
        /* margin-bottom: 10px; */
    }
}

/* Медиазапросы для экранов до 480px (смартфоны) */
@media (max-width: 480px) {
    .homepage-container {
        padding: 10px;
    }

    .navigation button:nth-child(1) {
        margin-bottom: 10px;
    }

    .header h1 {
        font-size: 28px;
    }

    .header h2 {
        font-size: 14px;
    }

    .navigation button,
    .button-link {
        width: 100%;
        /* margin-bottom: 10px; */
    }

    .navigation .search-input {
        width: 90%;
        margin-top: 10px;
    }
}

/* Дополнительные стили для кнопки, обернутой в Link */
.button-link {
    display: inline-block;
}

.full-width-button {
    width: auto;
}

@media (max-width: 768px) {
    .button-link {
        display: block;
        width: 100%;
    }

    .full-width-button {
        width: 100%;
        margin-bottom: 10px;
        margin-top: 10px;
    }

}
    /* .navigation button:nth-child(3),
    .navigation button:nth-child(4),
    .navigation .search-input {
        margin-bottom: 5px; /* Уменьшаем отступ снизу */
     
