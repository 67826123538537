/* src/components/Button.css
.button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: #0056b3;
  } */

  /* src/components/Button.css */
.button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    color: #fff!important; /* Белый текст */
    background-color: #007bff !important; /* Синий фон */
    border: none;
    border-radius: 5px !important;
    cursor: pointer;
    transition: background-color 0.3s ease !important;
  }
  
  .button:hover {
    background-color: #0056b3 !important; /* Темно-синий фон при наведении */
  }